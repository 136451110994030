const replaceSnakeCaseToSpace = (str: string) =>
  str && str.includes('_') ? str.replace(/_/g, ' ') : str;
const replaceCamelCaseToSentenceCase = (str: string) =>
  str.replace(/^[a-z]|[A-Z]/g, (v, i) => (i === 0 ? v.toUpperCase() : ' ' + v.toLowerCase()));
const replaceHyphensToSpace = (str: string) =>
  str && str.includes('-') ? str.replace(/-/g, ' ') : str;
const sanitizeString = (str: string) => str.replace(/(\r\n|\n|\r|\\n|\s)/gm, '');
const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export {
  capitalizeFirstLetter,
  replaceCamelCaseToSentenceCase,
  replaceHyphensToSpace,
  replaceSnakeCaseToSpace,
  sanitizeString,
};
