var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{ref:"customButton",tag:"component",staticClass:"tw-flex tw-items-center tw-font-gotham tw-transition",class:{
    'tw-w-full tw-justify-center': _vm.block,
    'tw-w-max': !_vm.block && !_vm.customSize,
    'tw-btn--icon': _vm.icon,
    'is-clickadilla': _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'tw-px-2.5 tw-h-6': _vm.size === 'xs' && _vm.icon,
    'tw-text-xs': _vm.size === 'xs' && _vm.text,
    'tw-px-2.5 tw-h-9': _vm.size === 'md' && _vm.icon,
    'tw-text-base': _vm.size === 'md' && _vm.text,
    'tw-px-4 tw-h-9': _vm.size === 'lg' && _vm.icon,
    'tw-text-lg': _vm.size === 'lg' && _vm.icon,
    'tw-text-sm': _vm.size === 'auto' && _vm.text,
    'tw-btn--text': _vm.text,
    'tw-btn--rounded': _vm.rounded,
    'tw-btn--disabled': _vm.disabled,
    'no-additional': !_vm.additionalStyles,
    'hover:tw-bg-mybid-secondary-1 focus:tw-bg-mybid-secondary-2':
      _vm.color === 'secondary' &&
      !_vm.disabled &&
      _vm.env.VUE_APP_ORG_NAME === 'Clickadilla' &&
      _vm.additionalStyles,
    'tw-bg-mybid-secondary dark:tw-bg-mybid-secondary tw-text-white tw-opacity-40':
      _vm.color === 'secondary' &&
      _vm.disabled &&
      _vm.env.VUE_APP_ORG_NAME === 'Clickadilla' &&
      _vm.additionalStyles,
    'tw-btn-primary': _vm.color === 'primary' && !_vm.disabled,
    'dark:tw-text-mybid-dark':
      ['primary', 'secondary'].includes(_vm.color) && _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'tw-border tw-border-solid tw-font-normal':
      ['outlined', 'outlined-light'].includes(_vm.color) && _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'dark:tw-text-mybid-primary tw-border-mybid-primary':
      _vm.color === 'outlined' && _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'dark:tw-text-white-absolute tw-border-mybid-gray-light':
      _vm.color === 'outlined-light' && _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'tw-btn-gradient': _vm.color === 'primary' && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'tw-btn-primary--light': _vm.color === 'primary-light' && !_vm.disabled,
    'tw-btn-secondary': _vm.color === 'secondary' && !_vm.disabled,
    'tw-btn-secondary--light': _vm.color === 'secondary-light' && !_vm.disabled,
    'hover:tw-text-mybid-secondary-3 hover:tw-bg-mybid-secondary-light-1 focus:tw-bg-mybid-secondary-light focus:tw-text-mybid-secondary dark:hover:tw-text-mybid-secondary-3 dark:hover:tw-bg-mybid-gray-dark-1 dark:focus:tw-bg-black-dark3 dark:focus:tw-text-mybid-secondary':
      ['primary-light', 'secondary-light'].includes(_vm.color) &&
      !_vm.disabled &&
      _vm.env.VUE_APP_ORG_NAME === 'Clickadilla' &&
      _vm.additionalStyles,
    'tw-text-mybid-secondary tw-bg-mybid-secondary-light dark:tw-bg-mybid-gray-light dark:tw-text-mybid-secondary tw-opacity-40':
      ['primary-light', 'secondary-light'].includes(_vm.color) &&
      _vm.disabled &&
      _vm.env.VUE_APP_ORG_NAME === 'Clickadilla' &&
      _vm.additionalStyles,
    'tw-p-2 tw-w-8 tw-h-8': _vm.color === 'research' && _vm.icon && _vm.size === 'sm',
    'tw-p-4': _vm.color === 'research' && _vm.icon && _vm.size === 'lg',
    'tw-text-black dark:tw-text-white tw-bg-gray-light':
      _vm.color === 'research' && _vm.env.VUE_APP_ORG_NAME !== 'Clickadilla',
    'tw-bg-gray-light4 tw-text-gray-dark dark:tw-text-gray-dark':
      _vm.color === 'research' && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'dark:tw-bg-mybid-gray-light': _vm.color === 'research' && _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
    'dark:tw-bg-gray-light': _vm.color === 'research' && _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'hover:tw-bg-gray-light5 focus:tw-bg-gray-light4 hover:tw-text-gray-dark1 focus:tw-text-gray-dark dark:hover:tw-bg-mybid-gray-dark-1 dark:focus:tw-bg-black-dark3 dark:hover:tw-text-gray dark:focus:tw-text-gray-dark':
      _vm.color === 'research' && !_vm.disabled && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'tw-opacity-20 tw-bg-gray-light5 tw-text-gray-dark dark:tw-text-gray-dark dark:tw-bg-mybid-gray-light':
      _vm.color === 'research' && _vm.disabled && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'tw-text-gray-dark dark:tw-text-gray': _vm.color === 'without-color' && !_vm.disabled,
    'tw-p-2': _vm.color === 'without-color' && _vm.icon,
    'tw-py-2 tw-px-3': _vm.color === 'without-color' && _vm.text,
    'hover:tw-text-gray-dark-2 focus:tw-text-gray-dark-3 dark:hover:tw-text-gray-dark-4 dark:focus:tw-text-gray-dark-2':
      _vm.color === 'without-color' && !_vm.disabled && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
    'tw-btn-error': _vm.color === 'error' && !_vm.disabled,
    'tw-whitespace-normal tw-max-w-full': _vm.textWrap,
    'tw-whitespace-nowrap': !_vm.textWrap,
    'tw-gap-1': _vm.size === 'sm',
    'tw-gap-2.5': _vm.size !== 'sm',
  },attrs:{"disabled":_vm.disabled || _vm.loading,"href":_vm.href},on:{"click":_vm.click,"mouseleave":_vm.clearFocused}},[(_vm.loading)?_c('Loader',{attrs:{"size":"xs"}}):[_vm._t("append-icon"),_vm._t("default"),_vm._t("prepend-icon")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }