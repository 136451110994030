export const SET_USER = 'SET_USER';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_AD_FORMATS = 'SET_AD_FORMATS';
export const SET_AD_FORMATS_DEFAULTS_DATA = 'SET_AD_FORMATS_DEFAULTS_DATA';
export const SET_ACCRUAL_RULE_TYPES = 'SET_ACCRUAL_RULE_TYPES';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ERROR = 'SET_ERROR';
export const SET_META_DATA = 'SET_META_DATA';
export const SET_MAINTENANCE = 'SET_MAINTENANCE';
export const SET_TECHNICAL_ALERTS = 'SET_TECHNICAL_ALERTS';
export const SET_ROLES = 'SET_ROLES';
export const SET_PROFILE_NOTIFICATIONS = 'SET_PROFILE_NOTIFICATIONS';
export const SET_MENU_NOTIFICATIONS = 'SET_MENU_NOTIFICATIONS';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
export const SET_BROADCAST_EVENTS = 'SET_BROADCAST_EVENTS';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_TAG_PERMISSIONS = 'SET_TAG_PERMISSIONS';
