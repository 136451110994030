

































































































































import moment from 'moment';
import qs from 'qs';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import Alert from "@/components/v2/Alert.vue";
import CloseIcon from "@/components/v2/Icons/CloseIcon.vue";
import WarningIcon from "@/components/v2/Icons/WarningIcon.vue";
import AdblockDetect from '@/components/v2/popups/AdblockDetect.vue';
import WelcomePopup from '@/components/v2/popups/Welcome.vue';
import Sidebar from '@/components/v2/Sidebar.vue';
import Snackbar from '@/components/v2/Snackbar.vue';
import content from '@/i18n/translations';
import { ApplicationState, ErrorIdentifier, Manager, Popup, UserState } from '@/interfaces';
import apiMixins from '@/mixins/apiMixins';
import notificationMixin from '@/mixins/notificationMixin';
import {
  LoginService,
  LogoutService,
  MenuService,
  PopupsService,
  ThemeService
} from '@/services/StorageService';
import { can } from '@/store/helpers';
import { LOGOUT } from '@/store/types/actions';
import { TOGGLE_MENU, TOGGLE_THEME } from '@/store/types/mutations';
import { getDateDiff } from '@/utils/dates';

import {
  STATS_FILTER_CONDITIONS,
  STATS_FILTER_INSERT_MODES,
  STATS_FILTER_OPERATORS,
} from './utils/constants';
import { parseValue } from './utils/parser';

interface ITopItem {
  visible: boolean;
  text: string;
  style?: string;
  customStyle?: string;
  link?: string;
  linkText?: string;
  icon?: string;
}

@Component({
  ...(process.env.VUE_APP_UI_VERION === '1' && {
    metaInfo: {
      title: `Publisher's panel by ${process.env.VUE_APP_ORG_NAME}`,
    },
  }),
  name: 'App',
  methods: { can },
  components: {
    WarningIcon,
    CloseIcon,
    Alert,
    AdblockDetect,
    Sidebar,
    Snackbar,
    WelcomePopup,
  },
})
export default class App extends Mixins(apiMixins, notificationMixin) {
  @Mutation(TOGGLE_MENU) toggleMenu!: () => void;
  @Mutation(TOGGLE_THEME) toggleTheme!: () => void;

  @Getter user!: UserState['user'];
  @Getter error!: ErrorIdentifier;
  @Getter metaData!: ApplicationState['metaData'];
  @Getter maintenance!: ApplicationState['maintenance'];
  @Getter menuOpened!: boolean;
  @Getter ownManager!: Manager | null;
  @Getter environment!: Environment;
  @Getter isDarkTheme!: boolean;
  @Getter emailVerified!: boolean;
  @Getter isAuthenticated!: boolean;
  @Getter technicalAlerts!: ApplicationState['technicalAlerts'];

  locale: Locale = window.navigator.language.split('-')[0] === 'ru' ? 'ru' : 'en';
  content = content[this.locale].top_fixed;
  topFixedHeight = 0;
  isTopFixedInit = false;
  LoginService = LoginService;

  get topItems(): ITopItem[] {
    return [...this.defaultTopItems, ...this.alertsTopItems];
  }

  get alertsTopItems(): ITopItem[] {
    return this.technicalAlerts.map(alert => {
      const isValidStart = alert.start_date
        ? new Date().getTime() - new Date(`${alert.start_date} ${alert.start_time}`).getTime() >= 0
        : true;

      const isValidEnd = alert.end_date
        ? new Date().getTime() - new Date(`${alert.end_date} ${alert.end_time}`).getTime() < 0
        : true;

      return {
        visible: isValidStart && isValidEnd,
        text: alert.text,
        customStyle: `color: ${alert.color}; background-color: ${alert.bg_color}; height: 20px`,
      };
    });
  }

  get defaultTopItems(): ITopItem[] {
    return [
      {
        visible: this.maintenance,
        text:
          this.env.VUE_APP_ORG_NAME === 'MyBid'
            ? 'Under maintenance'
            : 'Technical work is being carried out',
        style: 'tw-bg-red',
      },
      {
        visible: this.isAuthenticated && !this.emailVerified,
        text: this.content.confirm_email.text,
        linkText: this.content.confirm_email.link_text,
        link: '/profile',
        style: 'tw-bg-mybid-primary-light tw-text-mybid-primary',
      },
      {
        visible: this.isPaymentTermsVisible,
        text: this.content.payments_terms.text,
      },
    ];
  }

  calculateTopItemsHeight() {
    if (this.$refs.topFixed) {
      this.topFixedHeight = (this.$refs.topFixed as HTMLElement).offsetHeight;
      return;
    }

    this.topFixedHeight = 0;

    if (this.isTopFixedInit) return;

    // FIXME: remove setTimeout
    setTimeout(this.calculateTopItemsHeight, 300);
  }

  get isPaymentTermsVisible() {
    if (
      !this.user.data.registration_date ||
      !['Clickadilla'].includes(process.env.VUE_APP_ORG_NAME)
    )
      return false;
    const registrationDate = new Date(this.user.data.registration_date).getTime();
    const currentDate = new Date().getTime();
    const timeDifference = currentDate - registrationDate;
    const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000;

    return timeDifference <= fourteenDaysInMillis;
  }

  get popups() {
    return PopupsService.getPopups();
  }

  set popups(popups: Record<Popup, boolean>) {
    PopupsService.setPopups(popups);
  }

  get showAdBlockAlert() {
    return !(window as any).hasOwnProperty('isAdBlockDisabled');
  }

  get UIVersion() {
    return process.env.VUE_APP_UI_VERSION;
  }

  get manualNotifications() {
    return this.menuNotifications.data.data.filter(
      ({ type, status, created_at }) =>
        type === 'without_group' &&
        status === 'unmarked' &&
        getDateDiff(moment().toString(), moment(created_at).toString()) < 5
    );
  }

  created() {
    window.addEventListener('resize', this.calculateTopItemsHeight);

    const isDarkTheme = this.$vuetify.theme.dark;

    if (process.env.VUE_APP_ORG_NAME === 'Onclicka' && !isDarkTheme) {
      this.changeTheme();
    }
  }


  get env() {
    return process.env;
  }

  get isShowSignin() {
    return !this.$route?.meta?.redesign && !this.$route?.name && this.$route?.meta?.guest && !this.isAuthenticated;
  }

  async logout() {
    LoginService.removeLoginAsUser();
    LogoutService.setLogout();
    const token = await this.promiseWrapper(this.$store.dispatch(LOGOUT));
    if (token) return this.$router.push('/admin/users');
    await this.$router.push('/login');
  }

  expandMenu() {
    MenuService.setExpand();
    this.toggleMenu();
  }

  changeTheme() {
    this.$vuetify.theme.dark = !ThemeService.getDarkTheme();
    ThemeService.setDarkTheme(!ThemeService.getDarkTheme());
    this.toggleTheme();
  }

  getUserHash() {
    const parse = qs.parse(this.$route.hash, {
      decoder: parseValue,
      arrayLimit: Infinity,
    });
    const hash = Object.keys(parse).length
      ? {
          ...parse,
          activeMetrics: null,
          filters: {
            ...(parse.filters as Dictionary<any>),
            user_email: {
              values: [this.user.data.email],
              operator: STATS_FILTER_OPERATORS['EQUAL'],
              condition: STATS_FILTER_CONDITIONS['OR'],
              insertMode: STATS_FILTER_INSERT_MODES['SELECT'],
            },
          },
        }
      : {};
    return qs.stringify(hash, { encode: false, skipNulls: true });
  }

  resetUser() {
    LoginService.removeLoginAsUser();
    this.$router.go(0);
    this.$router.push(`/statistics/${this.getUserHash()}`);
  }

  @Watch('$route')
  onChangeRoute() {
    this.$nextTick(this.calculateTopItemsHeight);
  }
}
