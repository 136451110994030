import { ActionTree, GetterTree, MutationTree } from 'vuex';

import {AdFormatDefaultsState, RootState} from '@/interfaces';
import {SET_AD_FORMATS_DEFAULTS_DATA} from '@/store/types/mutations';

const state: AdFormatDefaultsState = {
  adFormatDefaults: {
    data: {
      native: null,
      push: null,
      calendar: null,
      inpage_push: null,
      popunder: null,
      interstitial: null,
      in_stream_ad: null,
      telegram_app_video: null,
      telegram_app_banner: null,
      telegram_app_inpage_push: null,
      banner: null,
      direct_link: null,
    },
    loading: false,
    error: null,
  },
};

const getters: GetterTree<AdFormatDefaultsState, RootState> = {
  adFormatsDefaults: state => state.adFormatDefaults.data,
}

const mutations: MutationTree<AdFormatDefaultsState> = {
  [SET_AD_FORMATS_DEFAULTS_DATA]: (state, payload: Partial<AdFormatDefaultsState['adFormatDefaults']['data']>) => {
    state.adFormatDefaults.data = {
      ...state.adFormatDefaults.data,
      ...payload,
    };
  },
};

const actions: ActionTree<AdFormatDefaultsState, RootState> = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
